<template>
  <div class="lottery-trend-pattern">
    <div class="header">
      <div class="title-box">
        <p class="title">形态走势</p>
        <intro-default cont=""></intro-default>
      </div>
      <div class="search-box">
        <select-user type="1" @change="page_change"></select-user>
      </div>
    </div>
    <div class="lz_cont">
      <trendTable :list="data" :config="[{ lable: '二同号', key: 'two' }, { lable: '三不同', key: 'three' }]"></trendTable>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Loading } from 'element-ui'
import trendTable from '@components/pc/trend-table'

export default {
  name: 'PatternTrend',
  props: {
    code: String
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [trendTable.name]: trendTable
  },
  data() {
    return {
      per_page: 10,
      data: [],
      loading: null
    }
  },
  computed: {
    tableRows() {
      if (this.data.length > 0) {
        return this.data[0].list[0]
      } else {
        return []
      }
    }
  },
  watch: {
    code: function (val, oldVal) {
      this.per_page = 10
      this.requestGetData()
    }
  },
  mounted() {
    this.requestGetData()
  },
  methods: {
    page_change(value) {
      this.per_page = value
      this.requestGetData()
    },
    refresh() {
      this.requestGetData()
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      const arr = ['table-cell']
      if ((row.two === '二同号' && columnIndex === 0) ||
        (row.three === '三不同' && columnIndex === 1)) {
        arr.push('active')
      }
      return arr.join(' ')
    },
    requestGetData() {
      this.$api.patternTrend(this.code, this.per_page).then((res) => {
        this.data = this.getGroupData(res.data)
        this.hideTableLoading()
      }).catch((err) => {
        this.hideTableLoading()
        this.$message.error(err)
      })
    },
    getGroupData(data) {
      const len = data?.length || 0
      if (len === 0) return []
      const n_arr = data[0]['list'][0]
      const obj = { title: '三军', list: [] }
      for (let i in n_arr) {
        obj.list.push(n_arr[i])
      }
      const arr = []
      arr.push(obj)
      return arr
    },
    showTableLoading() {
      if (this.loading) return
      this.loading = Loading.service({
        // target: '#table',
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0)'
      })
    },
    hideTableLoading() {
      this.$nextTick(() => {
        this.loading.close()
        this.loading = null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
@import "~@scss/pc/mixin";

.lottery-trend-pattern {
  >.header {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    >.title-box {
      display: flex;
      align-items: center;
      height: 49px;

      >.title {
        font-size: 20px;
        font-weight: 400;
        color: #222;
      }
    }

    >.search-box {
      display: flex;
      align-items: center;

      >.title {
        margin-right: 15px;
        font-size: 14px;
        color: #222;
      }

      >.icon-search {
        margin-left: 10px;
        width: 48px;
        height: 24px;
        display: block;
      }
    }
  }

  .lz_cont {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .single_class {
    display: block;
    width: 100%;
    height: 33px;
    line-height: 33px;
    background-color: #FEEBEB;
    color: #FA3E3E;
  }

  .double_class {
    display: block;
    width: 100%;
    height: 33px;
    line-height: 33px;
    background-color: #E6F6FE;
    color: #07A6F8;
  }
}
</style>

<style>
/* .lottery-trend-pattern>.table {
  margin-top: 20px;
} */

.lottery-trend-pattern>.table .table-header-cell {
  background-color: #f9f9f9;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  height: 40px;
}

.lottery-trend-pattern>.table .table-header-cell>.cell {
  padding: 0;
}

.lottery-trend-pattern>.table .table-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  padding: 0;
  height: 33px;
}

.lottery-trend-pattern>.table .table-cell .active {
  background-color: #75A430 !important;
  color: white !important;
}


.lottery-trend-pattern>.table .table-cell>.cell {
  padding: 0;
}

.lottery-trend-pattern>.table .table-row:hover>td,
.lottery-trend-pattern>.table .table-row .current-row>td {
  background-color: rgba(255, 204, 170, 0.4);
}
</style>
